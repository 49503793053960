import { useEffect, useState, useContext } from 'react';
import {
    makeStyles, tokens, shorthands, Button, SpinButton,
    Link,
    Caption1Strong,
    InfoLabel,
    DataGrid,
    DataGridHeader,
    DataGridHeaderCell,
    DataGridBody,
    DataGridRow,
    DataGridCell,
    TableCellLayout,
    TableColumnDefinition,
    createTableColumn,
    Body1Strong,
    TableColumnSizingOptions
} from '@fluentui/react-components';

import { NavLink } from 'react-router-dom';
import { GRAPH_PERMISSION_NAMES, ENGAGE_USER_ROLES } from '../constants';
import { fonts } from '../../styles'
import { date, dateTime } from '../../utils'

import SparkLine from '../../components/Sparkline';
import {
    Warning20Regular,
} from '@fluentui/react-icons';
import ConfirmationDialog from './ConfirmationDialog';
import { ContactsDataContext } from '../contexts/ContactsDataContext';
import { EngageAppContext } from '../contexts/EngageAppContext';
import ActionPopover from './ActionPopover';
import { useResponsive } from '../../hooks/useResponsive';

const useStyles = makeStyles({

    body: {
        ...fonts.body1,
    },
    footerRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: '32px',
        marginTop: tokens.spacingVerticalL
    },
    annotations: {
        display: 'flex',
        flexDirection: 'column',
        gap: tokens.spacingVerticalXS
        

    },
    orderStatusColumn: {
        whiteSpace: 'nowrap',
        overflowX: 'hidden',
        textOverflow: 'ellipsis',
        paddingRight: '10px'
    },
    infoText: {
        width: '280px'
    },
    dataGridRowNoLine: {
        borderBottom: 'none'
    },
    dataGridCellWithLine: {
        ...shorthands.borderBottom('1px', 'solid', tokens.colorNeutralStroke1),
    },
    navlink: {
        color: tokens.colorBrandForegroundLink,
        ':hover': {
            color: tokens.colorBrandForegroundLinkHover
        }
    }
});



export default function SubscriptionManagmentDataGrid(props: any) {

    const { engageProducts, setEngageProducts } = props;
    const { hasAnyEngageUserRole } = useContext(ContactsDataContext);
    const { consentedPermissions } = useContext(EngageAppContext);

    const hasRequiredApplicationConsent = consentedPermissions?.includes(GRAPH_PERMISSION_NAMES.ORGANIZATION_READ_ALL);


    if (!engageProducts || engageProducts.length === 0) {
        return null;
    }

    const engageProductsRefreshedAt = engageProducts[0].subscriptionDataRefreshedAt;

    const logFilePrefix = 'SubscriptionManagemtaDataGrid';
    const classes = useStyles();

    const { isDesktopOrLaptop } = useResponsive();

    /* Types */
    type SubscriptionCell = {
        label: string;
    }

    type QuantityCell = {
        quantity: number;
    }

    type CommitCell = {
        label: string;
        billingTerm: string;

    }

    type TermEndCell = {
        label: string;
    }

    type AutoTrimCell = {
        isAutoTrim: boolean;
    }

    type ProvisionedCell = {
        provisioned: JSX.Element;
        provisionedCurrentQuantity: number;
    }
    type UnassignedCell = {
        unassigned: JSX.Element;
        unasignedCurrentQuantity: number;
    }

    type Item = {
        subscriptionName: SubscriptionCell;
        provisioned: ProvisionedCell;
        unassigned: UnassignedCell;
        commit: CommitCell;
        autotrim: AutoTrimCell;
        termEnd: TermEndCell;
        quantity: QuantityCell;
        subs: any[];
        rowNumber: number;
        productId: string;
        subscriptionId: string;
        subscriptionQuantity: number;
        subscriptionNewQuantity: number;
        unfulfilledOrder: any;
    }


    const createProvisionedSparkline = (engageProduct: any) => {
        if (engageProduct.history.length === 0) {
            return <NavLink className={classes.navlink} to='/settings'><Warning20Regular /></NavLink>
        }

        const data = engageProduct.history.map((record: any, index: number) => {
            return record.subscribedSkuQuantity;
        })

        return createSparklineChart(data)
    }

    const createUnassignedSparkline = (engageProduct: any) => {
        // -1 is returned by the API when we cannot access subscribeSkus
        if (engageProduct.history.length === 0) {
            return <NavLink className={classes.navlink} to='/settings'><Warning20Regular /></NavLink>
        }

        const data = engageProduct.history.map((record: any, index: number) => {
            return record.subscribedSkuQuantity - record.subscribedSkuAssignedQuantity
        })

        return createSparklineChart(data)
    }

    const createSparklineChart = (data: any) => {
        return (<>
            <SparkLine data={data} showLegend={true} />
        </>)
    }

    /****** Item Mappings ******/

    const items: Item[] = engageProducts.flatMap((engageProduct: any) => (
        // Create a row for each subscription
        engageProduct.subscriptions.map((subscription: any, index: number) => {

            return ({

                subscriptionName: { label: engageProduct.productName },
                provisioned: {
                    provisioned: createProvisionedSparkline(engageProduct),
                    provisionedCurrentQuantity: engageProduct.quantity
                },
                unassigned: {
                    unassigned: createUnassignedSparkline(engageProduct),
                    unasignedCurrentQuantity: engageProduct.unassignedLicenses
                },

                commit: { label: subscription.commitmentTerm?.term || "N/A", billingTerm: subscription.billingTerm || "N/A" },
                autotrim: { isAutoTrim: subscription.isAutoZero },
                termEnd: { label: date(subscription.commitmentTerm?.endDate) },
                quantity: { quantity: subscription.quantity },
                subs: engageProduct.subscriptions,
                rowNumber: index,
                productId: engageProduct.productId,
                subscriptionId: subscription.subscriptionId,
                subscriptionQuantity: subscription.quantity,
                subscriptionNewQuantity: subscription.newQuantity,
                unfulfilledOrder: subscription.unfulfilledOrder

            })
        }

        )));


    /* column sizings */


    const columnSizingOptions: TableColumnSizingOptions = {
        subscriptionName: { minWidth: 230, defaultWidth: 350, idealWidth: 350 },
        provisioned: { minWidth: 120, defaultWidth: 120 },
        unassigned: { minWidth: 120, defaultWidth: 120 },
        commit: { minWidth: 50, defaultWidth: 60 },
        autoTrim: { minWidth: 50, defaultWidth: 90 },
        termEnd: { minWidth: 60, defaultWidth: 75 },
        quantity: { minWidth: 75, defaultWidth: 120 },
        orderStatus: { minWidth: 120, defaultWidth: 120 }
    }

    /* column Definitions */
    const columns: TableColumnDefinition<Item>[] = [
        createTableColumn<Item>({
            columnId: 'subscriptionName',
            compare: (a, b) => a.subscriptionName.label.localeCompare(b.subscriptionName.label),
            renderHeaderCell: () => 'Subscription Name*',
            renderCell: (item) => {
                var totalSubs = item.subs?.length || 1;
                var rowClass = (item.rowNumber + 1) === totalSubs ? classes.dataGridCellWithLine : classes.dataGridRowNoLine;

                return (item.rowNumber === 0 ?
                    <DataGridCell className={rowClass}>
                        <TableCellLayout truncate title={item.subscriptionName.label}>{item.subscriptionName.label}</TableCellLayout>
                    </DataGridCell> : <DataGridCell className={rowClass}></DataGridCell>)
            },
        }),
        createTableColumn<Item>({
            columnId: 'provisioned',
            compare: (a, b) => a.provisioned.provisionedCurrentQuantity - b.provisioned.provisionedCurrentQuantity,
            renderHeaderCell: () => 'Provisioned',
            renderCell: item => {

                var totalSubs = item.subs?.length || 1;
                var rowClass = item.rowNumber + 1 === totalSubs ? classes.dataGridCellWithLine : classes.dataGridRowNoLine;
                return (item.rowNumber === 0 ?
                    <DataGridCell className={rowClass}>
                        <TableCellLayout>{item.provisioned.provisioned}</TableCellLayout>
                    </DataGridCell>
                    : <DataGridCell className={rowClass}></DataGridCell>
                )
            }
        }),
        createTableColumn<Item>({
            columnId: 'unassigned',
            compare: (a, b) => a.unassigned.unasignedCurrentQuantity - b.unassigned.unasignedCurrentQuantity,
            renderHeaderCell: () => 'Unassigned',
            renderCell: item => {

                var totalSubs = item.subs?.length || 1;
                var rowClass = item.rowNumber + 1 === totalSubs ? classes.dataGridCellWithLine : classes.dataGridRowNoLine;
                return (item.rowNumber === 0 ? <DataGridCell className={rowClass}><TableCellLayout>{item.unassigned.unassigned}</TableCellLayout></DataGridCell>
                    : <DataGridCell className={rowClass}></DataGridCell>)
            }
        }),
        createTableColumn<Item>({
            columnId: 'commit',
            compare: (a, b) => a.commit.label.localeCompare(b.commit.label),
            renderHeaderCell: () => 'Commit',
            renderCell: item =>
                <DataGridCell className={classes.dataGridCellWithLine}>
                    <TableCellLayout>{item.commit.label} {item.commit.billingTerm === 'Annual' ? <sub><Caption1Strong>†</Caption1Strong></sub> : ""} </TableCellLayout>
                </DataGridCell>
        }),
        createTableColumn<Item>({
            columnId: 'autoTrim',
            compare: (a, b) => a.commit.label.localeCompare(b.commit.label),
            renderHeaderCell: () => <InfoLabel
                info={<div className={classes.infoText}>Engage will trim excess licensing every billing cycle.</div>}>Auto-trim
            </InfoLabel>,
            renderCell: item =>
                <DataGridCell className={classes.dataGridCellWithLine}>
                    <TableCellLayout>{item.autotrim.isAutoTrim ? 'Yes' : null}</TableCellLayout>
                </DataGridCell>
        }),
        createTableColumn<Item>({
            columnId: 'termEnd',
            compare: (a, b) => a.commit.label.localeCompare(b.commit.label),
            renderHeaderCell: () => 'Term End',
            renderCell: item =>
                <DataGridCell className={classes.dataGridCellWithLine}>
                    <TableCellLayout>{item.termEnd.label}</TableCellLayout>
                </DataGridCell>
        }),

        createTableColumn<Item>({
            columnId: 'quantity',
            compare: (a, b) => a.quantity.quantity - b.quantity.quantity,
            renderHeaderCell: () => 'Quantity',
            renderCell: item => {
                const lookupId = `${item.productId}:${item.subscriptionId}`
                const minQuantity = item.autotrim.isAutoTrim ? item.subscriptionQuantity : 1;

                return (
                    <DataGridCell className={classes.dataGridCellWithLine}>
                        <TableCellLayout>
                            {hasAnyEngageUserRole([
                                ENGAGE_USER_ROLES.PAX8_LICENSING_ADMIN,
                                ENGAGE_USER_ROLES.PAX8_LICENSING_MANAGER,
                                ENGAGE_USER_ROLES.ADMINISTRATOR
                            ]) && hasRequiredApplicationConsent
                                ?
                                <SpinButton
                                    id={lookupId}
                                    //className={classes.quantitySpinButton}
                                    value={item.subscriptionNewQuantity || item.subscriptionQuantity}
                                    displayValue={item.unfulfilledOrder
                                        ? getQuantityDisplay(item.unfulfilledOrder.quantity, item.unfulfilledOrder.newQuantity)
                                        : getQuantityDisplay(item.subscriptionQuantity, item.subscriptionNewQuantity)}
                                    min={minQuantity}
                                    onChange={onChangeFactory(lookupId)}
                                    disabled={item.commit.label === "N/A" || item.unfulfilledOrder}
                                />
                                : <span>{item.unfulfilledOrder
                                    ? getQuantityDisplay(item.unfulfilledOrder.quantity, item.unfulfilledOrder.newQuantity)
                                    : getQuantityDisplay(item.subscriptionQuantity, item.subscriptionNewQuantity)}</span>
                            }

                        </TableCellLayout>
                    </DataGridCell>)
            }
        }),
        createTableColumn<Item>({
            columnId: 'orderStatus',
            compare: (a, b) => a.commit.label.localeCompare(b.commit.label),
            renderHeaderCell: () => 'Order Status',
            renderCell: item =>
                <DataGridCell className={classes.dataGridCellWithLine}>
                    <TableCellLayout truncate className={classes.orderStatusColumn}>
                        {hasAnyEngageUserRole([
                            ENGAGE_USER_ROLES.PAX8_LICENSING_ADMIN,
                            ENGAGE_USER_ROLES.PAX8_LICENSING_MANAGER,
                            ENGAGE_USER_ROLES.ADMINISTRATOR
                        ]) && hasRequiredApplicationConsent
                            ?
                            item && item.unfulfilledOrder &&
                            <ActionPopover unfulfilledOrder={item.unfulfilledOrder} />
                            : item && item.unfulfilledOrder && <span>{item.unfulfilledOrder.status}</span>}
                    </TableCellLayout>
                </DataGridCell>
        }),
    ];




    const getQuantityDisplay = (old: any, updated: any) => {

        const updatedInt = parseInt(updated);
        if (!updatedInt) {
            return old;
        }

        if (old === updatedInt) {
            return old;
        }

        const delta = updatedInt - old;

        if (delta < 0) {
            return `${old} (-${Math.abs(delta)})`;
        }

        return `${old} (+${delta})`;
    }

    const onChangeFactory = (id: string) => (e: any, data: any) => {
        const newValue = data.value ?? data.displayValue;
        if (isNaN(newValue)) {
            return;
        }

        const splitId = id.split(':');
        const productId = splitId[0];
        const subscriptionId = splitId[1];

        var products = engageProducts.filter((x: any) => {
            if (x.productId === productId) {
                return x;
            }
        })

        var modifiedProduct = products[0];

        var subscription = modifiedProduct.subscriptions.filter((x: any) => {
            if (x.subscriptionId === subscriptionId) {
                return x;
            }
        })

        const modifiedSubscription = { ...subscription[0] };
        const minimumValue = modifiedSubscription.isAutoZero ? modifiedSubscription.quantity : 1;

        // Minimum value we will allow is 1
        if (newValue <= minimumValue) {
            modifiedSubscription.msProductId = modifiedProduct.productId
            modifiedSubscription.newQuantity = minimumValue;
        }
        else {
            modifiedSubscription.msProductId = modifiedProduct.productId
            modifiedSubscription.newQuantity = newValue;
        }

        modifiedProduct.subscriptions = modifiedProduct.subscriptions.map((x: any) => {
            // iterate through subscriptions, replace existing sub with modified
            if (x.subscriptionId === subscriptionId) {
                return modifiedSubscription
            }

            return x
        })

        // add modified sub
        //modifiedProduct.subscriptions = [...modifiedProduct.subscriptions, modifiedSubscription]
        let modifiedProducts = engageProducts.map((product: any) => {

            if (product.productId === productId) {
                return modifiedProduct
            }

            return product;

        })

        setEngageProducts(modifiedProducts);
    }


    return (
        <>
            <DataGrid
                resizableColumnsOptions={{ autoFitColumns: true }}
                columnSizingOptions={columnSizingOptions}
                items={items}
                //sortable
                columns={isDesktopOrLaptop ? columns : columns.filter(column => column.columnId !== 'provisioned')}
                resizableColumns
            >
                <DataGridHeader>
                    <DataGridRow>
                        {({ renderHeaderCell }) => (
                            <DataGridHeaderCell><Body1Strong>{renderHeaderCell()}</Body1Strong></DataGridHeaderCell>
                        )}

                    </DataGridRow>
                </DataGridHeader>
                <DataGridBody<Item>>
                    {({ item, rowId }) => (
                        <DataGridRow
                            className={classes.dataGridRowNoLine}
                            appearance='none'
                            key={rowId}
                        >
                            {({ renderCell }) => {
                                let totalSubs = item.subs?.length || 1;

                                return (
                                    <span className={classes.body}>
                                        {renderCell({ ...item, rowId })}
                                    </span>
                                );
                            }}
                        </DataGridRow>
                    )}
                </DataGridBody>
            </DataGrid>
            <div className={classes.footerRow}>
                <div>
                    {engageProducts.some((product: any) => product.subscriptions.some((subscription: any) => subscription.newQuantity && subscription.newQuantity != subscription.quantity)) &&
                        <ConfirmationDialog products={engageProducts} />
                    }
                </div>

                <div className={classes.annotations}>
                    <Caption1Strong>† Billed Annually instead of Monthly</Caption1Strong>
                    <Caption1Strong>* Subscriptions as of: {dateTime(engageProductsRefreshedAt)}</Caption1Strong>
                </div>
            </div></>);

}