import { useIdentity2 } from '../../hooks/useIdentity2';
import { createContext} from 'react';


export const IdentityDataContext = createContext({
    getAccessToken: () => Promise.resolve('') as Promise<string>,
    authError: false,
    tenantId: '',
    signOut: () => Promise.resolve() as Promise<void>,
    isInTeamsApp: false
});

export const IdentityDataProvider = ({isInTeams,children}: {isInTeams:boolean,children: any}) => {

    const {getAccessToken, authError, tenantId, signOut, isInTeamsApp} = useIdentity2(isInTeams);

        return (
            <IdentityDataContext.Provider value={{
                getAccessToken, authError, tenantId, signOut, isInTeamsApp
            }}>
                {children}
            </IdentityDataContext.Provider>
        );

}