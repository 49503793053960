import { useState, useEffect } from "react"
import { Button, Field, makeStyles, tokens, Spinner , Caption2} from "@fluentui/react-components";
import { Calendar } from '@fluentui/react-calendar-compat';
import { useRest } from "../hooks/useRest";
import { useResponsive } from "../hooks/useResponsive";

import { date, dateTimeLong, dateLocalizedFormatTimeOnly, getTimezone } from "../utils";
import { fonts } from "../styles";

const useStyles = makeStyles({

    schedulerLayout: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        maxHeight: '270px'
    },
    schedularLayoutMobile: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '600px'
    },
    calendarLayout: {
        marginRight: tokens.spacingHorizontalL
    },
    timeBlock: {
        marginRight: tokens.spacingHorizontalL
    },
    timeLayout: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'left',
        marginTop: tokens.spacingVerticalL,
        height: '210px',
        overflowY: 'auto',
        width: '200px',
        paddingRight: tokens.spacingHorizontalM
    },
    appointmentText: {
        marginTop: tokens.spacingVerticalS
    },
    button: {
        marginBottom: tokens.spacingVerticalS,
        width: '100%',
    },
    spinnerLayout: {
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        height: '100%'

    },
    buttonPressedStyle: {
        marginBottom: tokens.spacingVerticalS,
        backgroundColor: tokens.colorBrandBackground,
        color: tokens.colorNeutralForegroundInverted,
        width: '100%',
    },
    dateField: {
        ...fonts.label,
    },
    timeField: {
        ...fonts.label,
    },
    label: {
        ...fonts.label,
    }
});

export default function Scheduler(props: any) {
    const { isDesktopOrLaptop } = useResponsive();

    // I might need to pass in the available slots, and the onSlotChange / selectedSlot fuctionality
    const { selectedSlot, onSlotChange } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [bookingData, setBookingData] = useState<any>();
    const [buttonPressed, setButtonPressed] = useState<string>();
    const [filteredSlots, setFilteredSlots] = useState<any[]>();
    const [defaultDate, setDefaultDate] = useState<Date>(new Date());
    const [datesWithoutAvailability, setDatesWithoutAvailability] = useState<any[]>();

    const { GET } = useRest();


    const handleDateSelect = (selected: Date) => {
        onSlotChange(undefined);
        setButtonPressed(undefined);
        setFilteredSlots(bookingData?.slots.filter((x: any) => date(x.startTime) === date(selected)));
        setDefaultDate(selected);
    }

    const handleTimeSelect = (data: any) => {

        let slot = bookingData.slots.filter((x: any) => x.startTime === data.target.value)[0];
        onSlotChange(slot);
        setButtonPressed(data.target.id);
    }

    const getButtons = () => {
        return (
            <div>
                {
                    filteredSlots && filteredSlots.map((x: any) => {
                        return (
                            <div key={x.startTime}>
                                <Button id={x.startTime}
                                    className={buttonPressed === x.startTime ? classes.buttonPressedStyle : classes.button}
                                    onClick={handleTimeSelect}
                                    value={x.startTime}>{dateLocalizedFormatTimeOnly(x.startTime)}</Button>
                            </div>
                        );
                    })
                }
            </div>
        )
    }

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            const response = await GET(`${process.env.REACT_APP_FRONTDOOR_DATA_SERVICE_BASE_URL}/bookings?timezone=${encodeURIComponent(getTimezone())}`)
            const data = await response.json()
            setBookingData(data)

            // For every day between start and end date check if there are any slots available
            // If none are available remove them from the calendar
            let restrictedDates: Date[] = [];
            let trackingDate = new Date(data.startDate);
            while (trackingDate <= new Date(data.endDate)) {
                if (data.slots.some((x: any) => date(x.startTime) === date(trackingDate))) {
                    // do nothing
                } else {
                    restrictedDates.push(new Date(trackingDate));
                }

                trackingDate.setDate(trackingDate.getDate() + 1);
            }

            const firstDateWithAvailableSlots = new Date(data.slots[0].startTime);
            setDefaultDate(firstDateWithAvailableSlots);
            setFilteredSlots(data?.slots.filter((x: any) => date(x.startTime) === date(firstDateWithAvailableSlots)));

            setDatesWithoutAvailability(restrictedDates);
            
            setIsLoading(false);
        })()
    }, [])

    const classes = useStyles();

    return (
        <div>
            {!isLoading ?
                <div className={isDesktopOrLaptop ? classes.schedulerLayout : classes.schedularLayoutMobile}>
                    <div className={classes.calendarLayout}>
                        <Field className={classes.dateField}>Date</Field>
                        <Calendar
                            isMonthPickerVisible={false}
                            restrictedDates={datesWithoutAvailability}
                            onSelectDate={handleDateSelect}
                            minDate={new Date(bookingData?.startDate)}
                            maxDate={new Date(bookingData?.endDate)}
                            value={new Date(defaultDate)}
                            showGoToToday={false} />
                    </div>
                    <div className={classes.timeBlock}>
                        <Field className={classes.timeField}>Time <Caption2>({getTimezone()})</Caption2></Field>
                        <div className={classes.timeLayout}>
                            {getButtons()}
                        </div>
                    </div>
                    <div>
                        {selectedSlot &&
                            <>
                                <Field className={classes.label}>Appointment</Field>
                                <div className={classes.appointmentText}>
                                    {dateTimeLong(selectedSlot.startTime)}
                                </div>
                            </>
                        }
                    </div>
                </div> : <div className={classes.spinnerLayout}><Spinner label={"Retrieving availability"} /></div>
            }
        </div>
    )
}