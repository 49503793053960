import { useContext, useState, useEffect, useCallback } from 'react';
import { ENGAGE_USER_ROLES } from '../app/constants';
import { useRest } from '../hooks/useRest'
import { AccountsDataContext } from '../app/contexts/AccountsDataContext';
import { ContactsDataContext } from '../app/contexts/ContactsDataContext';
import { EngageAppContext } from '../app/contexts/EngageAppContext';
import { useLogging } from './useLogging';


export const useLicensingData = () => {
    const componentName: string = useLicensingData.name;
    const logFilePrefix = componentName;
    const { GET, POSTForm, POST } = useRest();
    const { accounts, selectedAccount } = useContext(AccountsDataContext);
    const { setIsComponentLoading, adminSiteSettings } = useContext(EngageAppContext);
    const { contact, hasEngageUserRole, hasAnyEngageUserRole } = useContext(ContactsDataContext);
    const { trackException } = useLogging();

    const [subscriptions, setSubscriptions] = useState<any[]>([]);
    const [orders, setOrders] = useState<any[]>([]);

    useEffect(() => {

        (async () => {
            if (!adminSiteSettings.isLicensingModuleEnabled) { return; }
            // has summit technology authorized the user?
            var hasRequiredEngageUserRoles = hasAnyEngageUserRole([
                ENGAGE_USER_ROLES.PAX8_LICENSING_ADMIN,
                ENGAGE_USER_ROLES.PAX8_LICENSING_MANAGER,
                ENGAGE_USER_ROLES.ADMINISTRATOR,
                ENGAGE_USER_ROLES.GLOBAL_READ
            ]);

            if (contact && !hasRequiredEngageUserRoles) { return; }

            try {

                setIsComponentLoading(componentName, true);

                var tenantId = selectedAccount()?.tenantId;
                if (!tenantId) {
                    return;
                }

                const dashboardTask = getSubscriptionData(tenantId);
                var ordersTask = getOrders();

                const [dashboardResult, ordersResult] = await Promise.all([dashboardTask, ordersTask]);

                setOrders(ordersResult);

                setSubscriptions(dashboardResult);




            }
            catch (error: any) {
            }
            finally {
                setIsComponentLoading(componentName, false);
            }

        })()

    }, [adminSiteSettings])

    async function getSubscriptionData(tenantId: string): Promise<any> {
        const response = await GET(`${process.env.REACT_APP_FRONTDOOR_DATA_SERVICE_BASE_URL}/subscriptions/${tenantId}/dashboard`);

        if (!response.ok) {
            throw new Error('Error retrieving subscriptions. Please try again later.');
        }

        return await response.json();
    }

    /// TODO: Consider combining this with getSubscription Data, it seems to only be missing the autozero data
    async function getSubscriptions(): Promise<any> {
        const selectedAccount = accounts.filter((x: any) => x.selected)[0];
        if (!selectedAccount?.tenantId)
            return;

        const response = await GET(`${process.env.REACT_APP_FRONTDOOR_DATA_SERVICE_BASE_URL}/subscriptions/${selectedAccount.tenantId}`);

        if (!response.ok) {
            throw new Error('Error retrieving subscriptions. Please try again later.');
        }

        return await response.json();
    }

    async function getSubscriptionAutoZeroStatus(): Promise<any> {
        const selectedAccount = accounts.filter((x: any) => x.selected)[0];
        if (!selectedAccount?.tenantId)
            return;

        const response = await GET(`${process.env.REACT_APP_FRONTDOOR_DATA_SERVICE_BASE_URL}/subscriptions/${selectedAccount.tenantId}/autozerostatuses`);

        if (!response.ok) {
            throw new Error('Error retrieving subscriptions. Please try again later.');
        }

        return await response.json();
    }

    async function setSubscriptionAutoZeroStatus(subscriptionId: string, autoZero: boolean): Promise<any> {
        const account = selectedAccount();
        if (!account?.tenantId)
            return;

        let autoZeroFormData = new FormData();
        autoZeroFormData.append("tenantId", account.tenantId);
        autoZeroFormData.append("autoZero", autoZero ? 'true' : 'false');

        const response = await POSTForm(
            `${process.env.REACT_APP_FRONTDOOR_DATA_SERVICE_BASE_URL}/subscriptions/${subscriptionId}/autozero`,
            autoZeroFormData
        );

        if (!response.ok) {
            throw new Error('Error saving settings. Please try again later.');
        }
    }

    async function getProducts(): Promise<any> {
        const selectedAccount = accounts.filter((x: any) => x.selected)[0];
        if (!selectedAccount?.tenantId)
            return;

        const response = await GET(`${process.env.REACT_APP_FRONTDOOR_DATA_SERVICE_BASE_URL}/products`);

        if (!response.ok) {
            throw new Error('Error retrieving subscriptions. Please try again later.');
        }

        return await response.json();
    }

    async function getProductPricing(productId: string) {
        const selectedAccount = accounts.filter((x: any) => x.selected)[0];
        if (!selectedAccount?.tenantId)
            return;

        const response = await GET(`${process.env.REACT_APP_FRONTDOOR_DATA_SERVICE_BASE_URL}/productPrice/${productId}`);

        if (!response.ok) {
            throw new Error('Error retrieving subscriptions. Please try again later.');
        }

        return await response.json();
    }

    // trying useCallback to see if that prevents rerenders
    const updateSubscriptions = useCallback(async (tenantId: string, subscriptions: any) => {
        const updates = subscriptions.map((sub: any) => {
            return {
                msProductId: sub.msProductId,
                subscriptionId: sub.subscriptionId,
                productId: sub.pax8ProductId,
                originalQuantity: sub.quantity,
                quantity: sub.newQuantity,
                startDate: new Date(),
                billingTerm: sub.billingTerm,
                commitmentTerm: sub.commitmentTerm.term,
                subscriptionUnitCost: sub.price
            }
        });

        try {
            setIsComponentLoading(componentName, true);

            const response = await POST(
                `${process.env.REACT_APP_FRONTDOOR_DATA_SERVICE_BASE_URL}/subscriptions/${tenantId}`,
                JSON.stringify(updates)
            );

            const refreshedSubscriptions = await getSubscriptionData(tenantId);
            setSubscriptions(refreshedSubscriptions);

            setIsComponentLoading(componentName, false);
            if (!response.ok) {
                throw new Error('Error saving subscriptions.');
            }
        }
        catch (error: any) {
            trackException(error);
        }
        finally {
            setIsComponentLoading(componentName, false);
        }

    }, []);

    const updateSubscriptionsOrderStatus = async (orderId: string, subscriptionId: string, action: string) => {

        const tenantId = selectedAccount()?.tenantId;

        if (!tenantId)
            return;


        const payload = {
            tenantId,
            action
        };

        const response = await POST(
            `${process.env.REACT_APP_FRONTDOOR_DATA_SERVICE_BASE_URL}/orders/${orderId}/subscriptions/${subscriptionId}`,
            JSON.stringify(payload)
        );

        const dashboardTask = getSubscriptionData(tenantId);
        setSubscriptions(await dashboardTask);
    }

    const getOrders = async () => {
        const selectedAccount = accounts.filter((x: any) => x.selected)[0];
        if (!selectedAccount?.tenantId)
            return;

        const response = await GET(`${process.env.REACT_APP_FRONTDOOR_DATA_SERVICE_BASE_URL}/subscriptions/${selectedAccount.tenantId}/orders`);

        if (!response.ok) {
            throw new Error('Error retrieving subscriptions. Please try again later.');
        }

        return await response.json();
    }

    return {
        subscriptions,
        orders,
        getProducts,
        getProductPricing,
        getSubscriptions,
        getSubscriptionAutoZeroStatus,
        setSubscriptionAutoZeroStatus,
        updateSubscriptions,
        updateSubscriptionsOrderStatus
    }
}