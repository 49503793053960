import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TabList, Tab, Divider, makeStyles, tokens, Button, shorthands } from "@fluentui/react-components"
import { Add24Regular, Open16Regular } from "@fluentui/react-icons";
import type {
    SelectTabData,
    SelectTabEvent,
    TabValue,
} from "@fluentui/react-components";

import StyledCard from "../../components/StyledCard"
import SubscriptionManagementDashboard from "./SubscriptionManagementDashboard";
import CostDashboard from "./CostDashboard";
import { EngageAppContext } from "../contexts/EngageAppContext";
import { LicensingDataContext } from "../contexts/LicensingDataContext";
import OrderHistoryDashboard from "./OrderHistoryDashboard";
import NoSubscriptionData from "./NoSubscriptionData";
import { DEFAULT_DESKTOP_PAGE_SIZE, GRAPH_PERMISSIONS, GRAPH_PERMISSION_NAMES } from "../constants";
import { useResponsive } from "../../hooks/useResponsive";

const useStyles = makeStyles({
    page: {
        minWidth: `${DEFAULT_DESKTOP_PAGE_SIZE}px`,
    },
    mobilePage: {
        width: '100%',
    },
    content: {
        marginTop: tokens.spacingVerticalL,
        minHeight: '500px'
    },
    tabbedHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'top',
        verticalAlign: 'top',
    },
    addSubscriptionButton: {
        marginTop: tokens.spacingVerticalM
    },
    consentButton: {
        marginBottom: tokens.spacingVerticalM,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    consentCard: {
        marginBottom: tokens.spacingVerticalL,
    }

})

export default function LicensingPage() {

    const navigate = useNavigate();
    const { subscriptions } = useContext(LicensingDataContext);
    const { isLoading, consentedPermissions } = useContext(EngageAppContext);
    const [selectedTab, setSelectedTab] = useState<TabValue>("subscriptions")
    const [hasConsent, setHasConsent] = useState<boolean>();
    const { isDesktopOrLaptop } = useResponsive();
    const classes = useStyles();

    useEffect(() => {
        setHasConsent(consentedPermissions && consentedPermissions.includes(GRAPH_PERMISSION_NAMES.ORGANIZATION_READ_ALL));
    }, [consentedPermissions]);

    const handleAddProductClick = () => {
        navigate('/subscriptions/request');
    }

    const getTabList = () => {

        return (
            <>
                <div className={classes.tabbedHeader}>
                    <TabList
                        selectedValue={selectedTab}
                        onTabSelect={handleTabSelect}>
                        <Tab value="subscriptions">Subscriptions</Tab>
                        <Tab value="cost">Cost</Tab>
                        <Tab value="history">History</Tab>
                    </TabList>
                    <div>
                        <Button appearance='secondary' onClick={handleAddProductClick} icon={<Add24Regular />} >Subscription</Button>
                    </div>
                </div>
            </>
        )
    }

    const handleTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
        setSelectedTab(data.value)
    }

    const handleConsentClick = () => {
        var scope = `scope=https://graph.microsoft.com/${GRAPH_PERMISSION_NAMES.ORGANIZATION_READ_ALL}`;
        var redirectUri = `redirect_uri=${process.env.REACT_APP_INITIATE_LOGIN_ENDPOINT}/consent-end.html`;
        var clientId = `client_id=${process.env.REACT_APP_API_CLIENT_ID}`
        const url = `https://login.microsoftonline.com/common/adminconsent?${clientId}&${scope}&${redirectUri}`;
        window.open(url, '_blank');
    }

    return (
        <div className={isDesktopOrLaptop ? classes.page : classes.mobilePage}>
            {!hasConsent &&
                <StyledCard className={classes.consentCard} title="Insufficient access granted - Admin Consent Required">
                    <div>
                        Managing licenses requires the following Microsoft Graph permission:
                        <ul>
                            <li>{GRAPH_PERMISSION_NAMES.ORGANIZATION_READ_ALL}</li>
                        </ul>
                    </div>

                    <div className={classes.consentButton}>
                        <Button onClick={handleConsentClick}
                            icon={<Open16Regular />}
                            iconPosition='after'
                            disabled={isLoading()}
                        >Grant admin consent</Button>
                    </div>
                </StyledCard>
            }
            <StyledCard>
                {!isLoading('useLicensingData') && subscriptions.length === 0 ?
                    <div className={classes.tabbedHeader}>
                        <NoSubscriptionData />
                        <div className={classes.addSubscriptionButton}>
                            <Button 
                                appearance='secondary' 
                                onClick={handleAddProductClick} 
                                icon={<Add24Regular />} >Subscription</Button>
                        </div>
                    </div>
                    :
                    <div>
                        {getTabList()}
                        <div className={classes.content}>
                            {selectedTab === "subscriptions" && <SubscriptionManagementDashboard />}
                            {selectedTab === "cost" && <CostDashboard />}
                            {selectedTab === "history" && <OrderHistoryDashboard />}
                        </div>
                    </div>
                }
            </StyledCard>
        </div>
    )
}