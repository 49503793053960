import { useMsal, useAccount, } from "@azure/msal-react";
import { useContext, useEffect, useState, useCallback } from "react";
import { LoggingContext } from "../app/contexts/LoggingContext";
import { TeamsFxContext } from "../app/Context";
import { CacheLookupPolicy, InteractionRequiredAuthError, AccountInfo } from "@azure/msal-browser";
import { scopes } from "../app/constants";

export const useIdentity2 = (isInTeams: boolean) => {
    const logFilePrefix = 'useIdentity';
    const errorTokenResponse = "ErrorNoToken";

    const [isInTeamsApp, setIsInTeamsApp] = useState<boolean>(isInTeams);
    const [authError, setAuthError] = useState<boolean>(false);
    const [tenantId, setTenantId] = useState<string>("");
    const [roles, setRoles] = useState<string[]>([]);

    const { trackEvent, trackTraceVerbose, trackException, trackTraceCritical,
        trackTraceWarning, trackTraceError, setAuthenticatedUserContext } = useContext(LoggingContext);

    const { teamsUserCredential } = useContext(TeamsFxContext);
    let account: AccountInfo | null = null;
    let ref: any = null;
    if (!isInTeams) {
        const { instance, accounts } = useMsal()//;
        ref = instance;
        account = useAccount(accounts[0] || {});
    }
    //const [tenantId, setTenantId] = useState<string>("");


    trackTraceVerbose(`${logFilePrefix}: useIdentity {isInTeams: ${isInTeams}}`);

    useEffect(() => {
        (async () => {
            const logName = `${logFilePrefix}-useEffect`;
            try {
                const accessToken = await getAccessToken();

                if (!accessToken) {
                    setAuthError(true);
                    return Promise.reject('Unable to retrieve access token.');
                }

                // extract roles from token
                trackTraceVerbose(`${logName}-parse-token`);
                var tokenParts = accessToken.split('.');
                var tokenPayload = JSON.parse(atob(tokenParts[1]));

                setRoles(tokenPayload.roles);
                trackTraceVerbose(`${logName}-roles-set: ${JSON.stringify(tokenPayload.roles)}`);

                //// set tenantId
                const tokenTenantId = tokenPayload.tid;
                setTenantId(tokenPayload.tid);

                // setAuthenticatedUserContext 
                const tokenUserId = tokenPayload.oid;
                setAuthenticatedUserContext(tokenUserId, tokenTenantId)
                trackTraceVerbose(`${logName}-setAuthenticatedUserContext-userId: ${tokenUserId}`)
                trackTraceVerbose(`${logName}-setAuthenticatedUserContext-tenantId: ${tokenTenantId}`)

            }
            catch (error: any) {
                trackException(error);
            }
        })();
    }, []);

    const teamsGetToken = async () => {
        try {
            if (!teamsUserCredential) {
                trackTraceVerbose("TeamsFx SDK is not initialized.");
                throw new Error("TeamsFx SDK is not initialized.");
            }
            const token = await teamsUserCredential.getToken("");
            // if (needConsent) {
            //trackTraceVerbose("Need consent, attempting to login.");
            //await teamsUserCredential!.login('User.Read');
            //}
            return token!.token;
        }
        catch (error: any) {
            trackTraceError(`${logFilePrefix}-getMsalToken-Error: ${error.message}`);
            setAuthError(true);
        }

        return errorTokenResponse;
    }

    const getMsalToken = async () => {
        const logName = `${logFilePrefix}-getMsalToken`;

        try {
            var silentRequest = {
                scopes,
                account,
                forceRefresh: false,
                cacheLookupPolicy: CacheLookupPolicy.RefreshToken // will default to CacheLookupPolicy.Default if omitted
            };

            var request = {
                scopes,
                loginHint: account!.username // For v1 endpoints, use upn from idToken claims
            };

            const tokenResponse = await ref.acquireTokenSilent(silentRequest).catch(async (error: any) => {
                trackTraceVerbose(`${logName}-acquireTokenSilent-error`);
                trackException(error);

                if (error instanceof InteractionRequiredAuthError) {
                    // fallback to interaction when silent call fails
                    return await ref.acquireTokenRedirect(request).catch(async (error: any) => {
                        trackTraceVerbose(`${logName}-acquireTokenRedirect-error`);
                        trackException(error);

                        return await ref.acquireTokenPopup(request).catch((error: any) => {
                            trackTraceCritical(`${logName}-acquireTokenPopup-error`);
                            trackException(error);
                            setAuthError(true);
                            throw error;
                        });
                    })
                }
            });

            return tokenResponse.accessToken;
        }
        catch (err: any) {
            trackTraceError(`${logFilePrefix}-getMsalToken-Error: ${err.message}`);
            setAuthError(true);
        }

        return errorTokenResponse;

    }

    const getAccessToken = async () => {
        const logName = `${logFilePrefix}-getAccessToken`;
        trackTraceVerbose(`${logName}`);

        if (!isInTeams) {
            return await getMsalToken();
        }
        else {
            return await teamsGetToken();
        }
    }

    const signOut = useCallback(async () => {
        debugger;
        const logName = `${logFilePrefix}-signOut`;
        trackTraceVerbose(`${logName}`);
        if (!isInTeams) {
            const logoutRequest = {
                account: account,
                postLogoutRedirectUri: process.env.REACT_APP_LOGOUT_REDIRECT_URL
            };
            ref.logoutRedirect(logoutRequest);
        }
    }, [isInTeams]);


    return {
        getAccessToken,
        signOut,
        tenantId,
        authError,
        isInTeamsApp
    };
}