import { useContext, useEffect } from "react";
import {
    Dialog,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogActions,
    DialogContent,
    Button,
} from "@fluentui/react-components";
import { useNavigate } from "react-router-dom";
import { LoggingContext } from "../contexts/LoggingContext";
import { TeamsFxContext } from "../Context";
import { scopes } from "../constants";

export default function Login() {

    const { teamsUserCredential } = useContext(TeamsFxContext);
    const { trackPageView, trackTraceError, trackEvent } = useContext(LoggingContext);
    const navigate = useNavigate();

    useEffect(() => {
        trackPageView('login');
    }, []);

    const handleLogin = async () => {
        trackEvent('login', 'Login button clicked, this should not be reached');
        // Navigate to the previous page.
        // navigate(-1);
    }

    return (
        <>
            <Dialog modalType="alert"
                open={true}>
                <DialogSurface>
                    <DialogBody>
                        <DialogTitle>Login</DialogTitle>
                        <DialogContent>
                            <div>
                                Please login to continue. 🥲
                            </div>
                            <div>
                                version: {process.env.REACT_APP_BUILD_NUMBER}
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button appearance="primary" onClick={handleLogin}>Login</Button>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </>
    );
}