import { useEffect, useState, useContext } from 'react';
import {
    TableHeader,
    TableBody, makeStyles, tokens, shorthands, Button, SpinButton,
    Link,
    Body1,
    Caption1Strong,
    Tooltip,
    InfoLabel,
    mergeClasses,
    DataGrid,
    DataGridHeader,
    DataGridHeaderCell,
    DataGridBody,
    DataGridRow,
    DataGridCell,
    TableCellLayout,
    TableColumnDefinition,
    createTableColumn
} from '@fluentui/react-components';

import { NavLink } from 'react-router-dom';
import { EngageAppContext } from '../contexts/EngageAppContext';
import { LicensingDataContext } from '../contexts/LicensingDataContext';
import { fonts } from '../../styles'
import { date } from '../../utils'

import SparkLine from '../../components/Sparkline';
import {
    Warning20Regular,
    ArrowSyncCheckmark24Regular
} from '@fluentui/react-icons';
import ConfirmationDialog from './ConfirmationDialog';
import EngageTable from '../../components/EngageTable';
import ActionPopover from './ActionPopover';
import SubscriptionManagmentDataGrid from './SubscriptionManagmentDataGrid';

export default function SubscriptionManagementDashboard() {

    const logFilePrefix = 'SubscriptionManagementDashboard';

    const { subscriptions } = useContext(LicensingDataContext);
    const [engageProducts, setEngageProducts] = useState<any[]>([]);

    useEffect(() => {
        (
            async () => {
                console.log('useEffect-subscriptionData')

                // Do nothing if no subscriptions are available
                if (subscriptions?.length <= 0) {
                    return;
                }

                const data = [...subscriptions];

                setEngageProducts(data);
            }
        )();
    }, [subscriptions]);

    return (

        <>
            <div>
                <SubscriptionManagmentDataGrid engageProducts={engageProducts} setEngageProducts={setEngageProducts} />
            </div>
        </>
    );
}

