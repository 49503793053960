import {
    tokens,
    makeStyles
} from '@fluentui/react-components'

import {
    HorizontalBarChart,
    IHorizontalBarChartStyles
}
    from '@fluentui/react-charting';
import { useResponsive } from '../../hooks/useResponsive';
import StyledCard from '../StyledCard';
import NoData from './NoData';

const useStyles = makeStyles({
    chart: {
        height: '300px',
        width: '450px',
        marginBottom: tokens.spacingVerticalL
    },
    chartMobile: {
        width: '100%',
        marginBottom: tokens.spacingVerticalL
    }
});

function TopTicketSubmitters({ highestTicketSubmitterData, maxRenderSize }: any) {
    const total = highestTicketSubmitterData.totalTickets;

    const classes = useStyles();
    const { isDesktopOrLaptop } = useResponsive();

    const data = highestTicketSubmitterData.topTicketSubmitters.map((employee: any) => {
        return {
            chartTitle: employee.name,
            chartData: [{
                legend: employee.name,
                horizontalBarChartdata: { x: employee.value, y: total },
                color: tokens.colorBrandForeground1
            }]
        }
    }).slice(0, maxRenderSize);

    const horizontalBarChartStyles: IHorizontalBarChartStyles = {
        chartTitleRight: {
            color: tokens.colorNeutralForeground1
        },
        chartTitle: {
            color: tokens.colorNeutralForeground1
        },
        root: {},
        items: {},
        chart: {},
        chartWrapper: {},
        barWrapper: {},
        chartTitleLeft: { color: tokens.colorNeutralForeground1 },
        chartDataTextDenominator: {},
        benchmarkContainer: {},
        triangle: {},
        barLabel: {}
    }

    return (
        <>
            <StyledCard title="Top Ticket Submitters" className={isDesktopOrLaptop ? classes.chart : classes.chartMobile}>
                {data.length === 0 ? <NoData /> :
                    <HorizontalBarChart
                        styles={horizontalBarChartStyles}
                        data={data}
                    />
                }
            </StyledCard>
        </>
    );
}

export default TopTicketSubmitters;