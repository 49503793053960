import { DonutChart } from '@fluentui/react-charting';
import StyledCard from '../StyledCard';
import NoData from './NoData';
import { tokens, makeStyles } from '@fluentui/react-components';
import { useResponsive } from '../../hooks/useResponsive';

import { TicketOverviewReport } from '../../types/reportingTypes';

const useStyles = makeStyles({
    chart: {
        height: '300px',
        width: '350px',
    },
    mobileChart: {
        width: '100%',
        marginBottom: tokens.spacingVerticalL
    },
    donutChart: {
        stroke: tokens.colorNeutralBackground1,
        '& text': {
            fill: tokens.colorNeutralForeground1,
            fontWeight: tokens.fontWeightBold,
            fontSize: tokens.fontSizeBase600,
            lineHeight: tokens.lineHeightBase200,
        },
        '& path': {
            stroke: tokens.colorNeutralBackground1,
        }
    },
});


function CasesOverview({ casesOverviewData }: { casesOverviewData: TicketOverviewReport, className?: string }) {

    const classes = useStyles();
    
    const { isDesktopOrLaptop } = useResponsive();

    const points = [
        { legend: 'Open', data: casesOverviewData.openTickets ?? 0, color: tokens.colorBrandForeground1 },
        { legend: 'On Hold', data: casesOverviewData.onHoldTickets ?? 0 }, // TODO
        { legend: 'Closed', data: casesOverviewData.closedTickets ?? 0 } // TODO
    ];

    const data = {
        chartTitle: 'Donut chart basic example',
        chartData: points,
    };

    const total = points.reduce((acc, val) => {
        return acc + val.data
    }, 0);

    return (
        <StyledCard title='Tickets Overview' className={isDesktopOrLaptop ? classes.chart : classes.mobileChart }>
            {total === 0 ? <NoData /> :
                    <DonutChart
                        className={classes.donutChart}
                        data={data}
                        innerRadius={55}
                        hideLegend={false}
                        hideLabels={true}
                        height={250}
                        valueInsideDonut={total}
                        legendProps={{
                            allowFocusOnLegends: true,
                            styles: {
                                text: {
                                    color: tokens.colorNeutralForeground1
                                }
                            }
                        }}
                    />
            }
        </StyledCard>
    );
}

export default CasesOverview;