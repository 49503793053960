import { useContext, useEffect, useState } from "react";
import { FluentProvider, makeStyles, tokens, shorthands, Button, mergeClasses, teamsDarkTheme, teamsLightTheme, teamsHighContrastTheme } from "@fluentui/react-components";
import { Outlet, useNavigate, useParams, useSearchParams } from "react-router-dom";
import AuthenticationProvider from "../providers/AuthenticationProvider";
import Header from "../components/header/Header";
import Welcome from "./welcome/page";
import { NotificationsDataProvider } from "./contexts/NotificationsDataContext";
import { fonts } from "../styles";
import { AccountsDataContext, AccountsDataProvider } from "./contexts/AccountsDataContext";
import { TicketsDataProvider } from "./contexts/TicketsDataContext";
import { IdentityDataProvider, IdentityDataContext } from "./contexts/IdentityDataContext2";
import { LoggingContext } from "./contexts/LoggingContext";
import ErrorPage from "../error-page";
import { ERROR_CODES, DEFAULT_DESKTOP_PAGE_SIZE, ENGAGE_USER_ROLES } from "./constants";
import { EngageAppContext, EngageAppProvider } from "./contexts/EngageAppContext";
import { LicensingDataProvider } from "./contexts/LicensingDataContext";
import { ContactsDataContext, ContactsDataProvider } from "./contexts/ContactsDataContext";
import { useAppStyles } from "../styles";
import { useResponsive } from "../hooks/useResponsive";
import { app } from "@microsoft/teams-js";

const useStyles = makeStyles({
    app: {
        boxSizing: 'border-box',
        paddingTop: tokens.spacingVerticalXL,
        paddingLeft: '10%',
        paddingRight: '10%',
        backgroundColor: tokens.colorNeutralBackground1,
        ...fonts.body,
        scrollbarColor: `${tokens.colorNeutralForeground2} ${tokens.colorNeutralBackground3}`,
        display: 'flex',
        justifyContent: 'center',
    },
    appContent: {
        maxWidth: `${DEFAULT_DESKTOP_PAGE_SIZE}px`,
    },
    appContentMobile: {
        width: '100%',
    },
    content: {
        marginTop: tokens.spacingVerticalXL,
    },
    errorPage: {
        paddingTop: tokens.spacingVerticalXL,
    }
});

// In order to access the account context data we need to wrap the entire app in the AccountsDataProvider
const AccountInnerComponent = () => {
    const classes = useStyles();
    const appStyles = useAppStyles();
    const navigate = useNavigate();
    var routeParams = useParams();
    const [searchParams, setSearchParams] = useSearchParams();


    const { getSessionId, trackEvent, trackTraceInformation } = useContext(LoggingContext);
    const { accountError, selectedAccount, accounts } = useContext(AccountsDataContext);
    const { adminSiteSettings } = useContext(EngageAppContext);
    const { hasAnyEngageUserRole } = useContext(ContactsDataContext);
    const { isDesktopOrLaptop } = useResponsive();

    useEffect(() => {

        if (Object.keys(routeParams).length > 0) {
            // User is attempting to navigate to a specific route
            // don't route them
            return;
        }

        const tenantId = selectedAccount()?.tenantId;
        if (!tenantId) {
            trackEvent('AccountInnerComponent-noTenantId-guard', { selectedAccount: selectedAccount() });
            return;
        }

        var hasLicensingRole = hasAnyEngageUserRole([
                                ENGAGE_USER_ROLES.PAX8_LICENSING_ADMIN,
                                ENGAGE_USER_ROLES.PAX8_LICENSING_MANAGER,
                                ENGAGE_USER_ROLES.ADMINISTRATOR
                            ]);
        // Default user to licensing page if their
        if (adminSiteSettings.isLicensingModuleEnabled && hasLicensingRole) {
            navigate('/subscriptions?tenantId=' + tenantId, { replace: true });
        }
        else {
            navigate('/?tenantId=' + tenantId, { replace: true });
        }

    }, [adminSiteSettings]);

    return (
        <>
            <div id='appStyles' className={mergeClasses(classes.app, appStyles.appBackground)}>
                <LicensingDataProvider>
                    <TicketsDataProvider>
                        <NotificationsDataProvider>
                            {accountError === ERROR_CODES.ACCOUNT_NOT_FOUND && <Welcome />}
                            {accountError === ERROR_CODES.SERVICE_UNAVAILABLE && <ErrorPage sessionId={getSessionId()} errorType={ERROR_CODES.SERVICE_UNAVAILABLE} />}
                            {!accountError &&
                                <div className={isDesktopOrLaptop ? classes.appContent : classes.appContentMobile}>
                                    <Header />
                                    <div id='contentStyles' className={classes.content}>
                                        <Outlet />
                                    </div>
                                </div>
                            }
                        </NotificationsDataProvider>
                    </TicketsDataProvider>
                </LicensingDataProvider>
            </div>
        </>
    )
}


const InnerComponent = () => {

    const classes = useStyles();
    const { getSessionId } = useContext(LoggingContext);
    const { authError } = useContext(IdentityDataContext);

    return (

        <>
            {authError && <div className={classes.errorPage}><ErrorPage sessionId={getSessionId()} errorType={"AuthError"} /></div>}
            {!authError &&
                <AccountsDataProvider>
                    <EngageAppProvider>
                        <ContactsDataProvider>
                            <AccountInnerComponent />
                        </ContactsDataProvider>
                    </EngageAppProvider>
                </AccountsDataProvider>
            }
        </>
    );
}

export default function Root(props: any) {
    const logFilePrefix = 'root';
    const { prefersDark } = props;

    const [isInTeams, setIsInTeams] = useState<boolean | null>(null);
    const [appTheme, setAppTheme] = useState<any>(null);
    const [themeString, setThemeString] = useState<string | null>(null);
    const [host, setHost] = useState<string | null>(null);

    const { trackTraceVerbose, trackException, trackEvent } = useContext(LoggingContext);
    const classes = useStyles();
    const version = process.env.REACT_APP_BUILD_NUMBER;

    const themeDefault = prefersDark ? teamsDarkTheme : teamsLightTheme;

    useEffect(() => {

        if (appTheme !== null && themeString !== null) {
            trackEvent(`${logFilePrefix}-setTheme:`, { theme: themeString });
        }

        if (isInTeams !== null && host !== null) {
            trackEvent(`${logFilePrefix}-host:`, { host: host });
        }

    }, [appTheme, isInTeams])

    useEffect(() => {

        (async () => {
            try {
                await app.initialize();
                var context = await app.getContext();
                trackTraceVerbose(`${logFilePrefix}-context: ${JSON.stringify(context)}`);
                trackTraceVerbose(`${logFilePrefix}-context-theme: ${JSON.stringify(context.app.theme)}`);

                const themeString = context.app.theme;
                setThemeString(themeString);
                const theme = themeString === "dark"
                    ? teamsDarkTheme
                    : themeString === "contrast"
                        ? teamsHighContrastTheme
                        : {
                            ...teamsLightTheme,
                            colorNeutralBackground3: "#eeeeee",
                        }

                setHost(context.app.host.name);
                setAppTheme(theme ?? themeDefault);
                setIsInTeams(true);
            }
            catch (error: any) {
                if (error.message === "Initialization Failed. No Parent window found."
                    || error.message === "SDK initialization timed out."
                ) {
                    setIsInTeams(false);
                    setAppTheme(themeDefault);
                    setThemeString(prefersDark ? "dark" : "light");
                    setHost("standalone");
                }
                else {
                    trackException(error);
                }
            }
        })();
    }, [])

    trackTraceVerbose(`${logFilePrefix}-version: ${version}`);
    trackTraceVerbose(`${logFilePrefix}-return-component`);

    return (
        isInTeams !== null && appTheme &&
        <FluentProvider id='app' theme={appTheme}>
            {isInTeams !== null &&
                <AuthenticationProvider isInTeams={isInTeams}>
                    <IdentityDataProvider isInTeams={isInTeams}>
                        <InnerComponent />
                    </IdentityDataProvider>
                </AuthenticationProvider>
            }
        </FluentProvider>
    );
}