import {
    useContext
} from 'react'

import { TicketsDataContext } from '../../app/contexts/TicketsDataContext';
import StyledCard from '../StyledCard';

import {
    makeStyles,
    Table,
    TableHeader,
    TableRow,
    TableHeaderCell,
    TableBody,
    TableCell,
    shorthands
} from '@fluentui/react-components';

import { useResponsive } from '../../hooks/useResponsive';
import { fonts, useTableStyles } from '../../styles';
import { dateTime } from '../../utils';
import { STATUS_TYPES } from '../../app/constants';

import NoTickets from './NoTickets';
import { useNavigate } from 'react-router-dom';
import { ENGAGE_USER_ROLES } from '../../app/constants';
import { ContactsDataContext } from '../../app/contexts/ContactsDataContext';
import { AccountsDataContext } from '../../app/contexts/AccountsDataContext';
import EngageTable from '../EngageTable';

export default function ClosedTickets({ className }) {

    const navigate = useNavigate();

    const { isDesktopOrLaptop } = useResponsive();
    const { tickets, ticketsLoading, ticketError } = useContext(TicketsDataContext);
    const {  hasAnyEngageUserRole } = useContext(ContactsDataContext);
    const { appendTenantIdToLink } = useContext(AccountsDataContext);

    let closedCases = [];
    if (!ticketError) {
        closedCases = tickets.filter(x => {
            return x.statusType === STATUS_TYPES.CLOSED;
        }).sort((a, b) => new Date(b.closedAt) - new Date(a.closedAt))
    }

    const tableClasses = useTableStyles();

    // TODO: This is a hack to determine if the user has the ServiceManager.Read role
    // Create const for different application roles
    // look into a better way to do this, conditional statments may get to be too tedious
    const displayTicketCreator = hasAnyEngageUserRole([
        ENGAGE_USER_ROLES.ADMINISTRATOR,
        ENGAGE_USER_ROLES.GLOBAL_READ,
        ENGAGE_USER_ROLES.SERVICE_MANAGER,
        ENGAGE_USER_ROLES.PAX8_LICENSING_ADMIN,
        ENGAGE_USER_ROLES.PAX8_LICENSING_MANAGER
    ])

    return (
        <StyledCard
            className={className}
            title='Closed Tickets in the Last 30 Days'
            isLoading={ticketsLoading}
            error={ticketError}
        >
            {closedCases.length > 0 ?
                <EngageTable
                    size='small'>
                    <TableHeader>
                        <TableRow>
                            {/* TODO This needs to be generated based on API Request */}
                            <TableHeaderCell>Title</TableHeaderCell>
                            {displayTicketCreator && isDesktopOrLaptop && <TableHeaderCell>Ticket Creator</TableHeaderCell>}
                            {isDesktopOrLaptop &&
                                <>
                                    <TableHeaderCell>Created</TableHeaderCell>
                                    <TableHeaderCell>Closed</TableHeaderCell>
                                </>
                            }
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {closedCases.map((x) => {
                            return (
                                <TableRow
                                    className={tableClasses.rowClickable}
                                    onClick={() => navigate(appendTenantIdToLink(`/ticketDetails/${x.id}`))}
                                    key={x.id}>
                                    <TableCell
                                        className={tableClasses.truncatedTableData}
                                    >
                                        {x.subject}
                                    </TableCell>
                                    {displayTicketCreator && isDesktopOrLaptop && <TableCell>{x.contactName}</TableCell>}
                                    {isDesktopOrLaptop &&
                                        <>
                                            <TableCell>{dateTime(x.createdAt)}</TableCell>
                                            <TableCell>{dateTime(x.closedAt)}</TableCell>
                                        </>
                                    }
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </EngageTable>
                : <NoTickets message='There are no closed tickets assigned to you' />
            }
        </StyledCard>
    )
};