import { useEffect, useState, useContext, useCallback } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { IdentityDataContext } from '../app/contexts/IdentityDataContext2'
import { useRest } from './useRest';
import { Account } from '../types/accountTypes';
import { LoggingContext } from '../app/contexts/LoggingContext';
import { ERROR_CODES } from '../app/constants';

export const useAccountsData = () => {

    const logFilePrefix = 'useAccountsData';

    const [searchParams, setSearchParams] = useSearchParams();
    const { tenantId: defaultTenantId } = useContext(IdentityDataContext);
    const searchParamTenantId = searchParams.get('tenantId');
    
    // Contexts
    const { trackEvent, trackException, trackTraceVerbose, trackTraceError, trackTraceWarning } = useContext(LoggingContext);

    // State
    const [accounts, setAccounts] = useState<Account[]>([]);
    const [accountError, setAccountError] = useState<any | undefined>(undefined);
    const [accountsLoading, setAccountsLoading] = useState(true);

    // Hooks
    const { GET } = useRest();

    useEffect(() => {
        (async () => {
            const logName = `${logFilePrefix}-useEffect`;
            trackTraceVerbose(`${logName}`);

            if (!searchParamTenantId && !defaultTenantId) {
                trackTraceWarning(`${logName}-guardClause: deps[tenantId]`)
                return;
            }

            searchParams.set('tenantId', searchParamTenantId || defaultTenantId);
            setSearchParams(searchParams);

            try {

                let accountResponse: Response;
                try {
                    accountResponse = await GET(`${process.env.REACT_APP_FRONTDOOR_DATA_SERVICE_BASE_URL}/accounts`);
                }
                catch (error: any) {
                    setAccountError({ errorMessage: error.message });
                    trackTraceError(`${logFilePrefix}-GET: ${error.message}`);
                    trackException(error);
                    return;
                }

                if (accountResponse.status === 401) {
                    // a 401 at this point means that the original request failed
                    // and obtaining the token silently failed as well
                    // a user initiated login request must be performed
                    trackEvent('useAccountsData-401-UiLoginRequired', { accountResponse });

                    setAccounts([]);
                    return;
                    // set isUiLoginRequired to true if login fails
                }

                if (accountResponse.status === 403) {
                    trackEvent('useAccountsData-403', { accountResponse });
                    const error = ERROR_CODES.ACCOUNT_NOT_FOUND

                    setAccountError(error);
                    return;
                    // The user is authenticated but does not have access to the endpoint
                }

                // API is offline
                if (accountResponse.status === 503) {
                    const error = ERROR_CODES.SERVICE_UNAVAILABLE
                    trackTraceError(`${logFilePrefix}-GET: ${error}`);
                    setAccountError(error);
                    return;
                }

                if (!accountResponse.ok) {
                    setAccountError({ errorMessage: accountResponse.statusText });
                    trackTraceError(`${logFilePrefix}-GET: ${accountResponse.statusText}`);
                    return;
                }

                if (accountResponse.status === 200) {
                    const accounts = await accountResponse.json();

                    const comparisonTenantId = searchParamTenantId || defaultTenantId;

                    const accountsMapped = accounts.map((x: any) => {
                        return {
                            ...x,
                            selected: x.tenantId === comparisonTenantId
                        }
                    });

                    trackTraceVerbose(`${logName}-accountsMapped`);

                    setAccounts(accountsMapped);
                    setAccountsLoading(false);
                }

                if (!accountResponse.ok) {
                    trackException(new Error(`Unable to retrieve accounts. ResponseStatus: ${accountResponse.status}`))
                }
            }
            catch (error: any) {
                trackException(error);
            }
        })();

    }, [defaultTenantId]);

    const selectedAccount = useCallback(() => {
        return accounts.filter(a => a.selected)[0];
    }, [accounts])

    const selectAccount = (tenantId: string) => {
        const updatedAccounts = accounts.map(account => ({
            ...account,
            selected: account.tenantId === tenantId
        }));

        searchParams.set('tenantId', tenantId);
        setSearchParams(searchParams);

        setAccounts(updatedAccounts);
    }

    const appendTenantIdToLink = (url: string) => {
        return `${url}${url.includes('?') ? '&' : '?'}tenantId=${selectedAccount()?.tenantId}`;
    }


    return {
        accounts,
        accountsLoading,
        selectAccount,
        accountError,
        selectedAccount,
        appendTenantIdToLink
    }
}