import { useState, useEffect, useContext } from 'react';
import { useRest } from '../hooks/useRest';
import { useLogging} from '../hooks/useLogging';
import { AccountsDataContext } from '../app/contexts/AccountsDataContext';

type EngageAppSettings = {
    AdminSettings: AdminSiteSettings,
    LicensingSettings: TenantSiteSettings
}

export type AdminSiteSettings = {
    isLicensingModuleEnabled: boolean,
    maxCalculatedOrderCostThreshold: number,
    allowedNewCommitmentTerms: string[],
    isSubscriptionRightsizingEnabled: boolean
}

export type TenantSiteSettings = {
    licensingNotificationRecipients: string[]
}

const defaultAdminSiteSettings: AdminSiteSettings = {
    allowedNewCommitmentTerms: [""],
    isLicensingModuleEnabled: false,
    isSubscriptionRightsizingEnabled: false,
    maxCalculatedOrderCostThreshold: 0
}

const defaultTenantSiteSettings: TenantSiteSettings = {
    licensingNotificationRecipients: [""]
}

export const useEngageApp = () => {
    const componentName = 'useEngageApp'

    const [loadingArray, setLoadingArray] = useState<string[]>([]);
    const [consentedPermissions, setConsentedPermissions] = useState<string[]>([""]);
    const [adminSiteSettings, setAdminSiteSettings] = useState<AdminSiteSettings>(defaultAdminSiteSettings);
    const [tenantSiteSettings, setTenantSiteSettings] = useState<TenantSiteSettings>(defaultTenantSiteSettings);

    const { accounts, selectedAccount } = useContext(AccountsDataContext);

    const { trackException } = useLogging();

    const { GET, POST } = useRest();

    useEffect(() => {
        (async () => {
            const tenantId = selectedAccount()?.tenantId

            if (!tenantId) {
                return;
            }


            //setIsComponentLoading(componentName, true);
            const response = await GET(`${process.env.REACT_APP_FRONTDOOR_DATA_SERVICE_BASE_URL}/settings/${tenantId}`)
            const data = await response.json()

            setAdminSiteSettings(data.adminSettings);
            setTenantSiteSettings(data.licensingSettings)
            setConsentedPermissions(data.consentedPermissions);

        })();
    }, [accounts])


    const updateAdminSiteSettings = async (adminSettings: AdminSiteSettings) => {
        await updateSettings(adminSettings, tenantSiteSettings);
    }

    const updateTenantSiteSettings = async (tenantSettings: TenantSiteSettings) => {
        await updateSettings(adminSiteSettings, tenantSettings);
    }

    const updateSettings = async (adminSettings: AdminSiteSettings, tenantSettings: TenantSiteSettings) => {

        const tenantId = selectedAccount()?.tenantId

        if (!tenantId) {
            return;
        }

        const settings: EngageAppSettings = {
            AdminSettings: adminSettings,
            LicensingSettings: tenantSettings
        }

        var loadingName = `${componentName}-updateSettings`

        try {
            setIsComponentLoading(loadingName, true);
            setAdminSiteSettings(adminSettings);
            setTenantSiteSettings(tenantSettings);
            const response = await POST(`${process.env.REACT_APP_FRONTDOOR_DATA_SERVICE_BASE_URL}/settings/${tenantId}`, JSON.stringify(settings));
        }
        catch (err: any) {
            trackException(err);
        }
        finally {
            setIsComponentLoading(loadingName, false);
        }
    }

    function setIsComponentLoading(componentName: string, loadingStatus: boolean) {
        if (loadingStatus) {
            setLoadingArray(previousState => [...previousState, componentName])
        }
        else {
            setLoadingArray(previousState => [...previousState.filter(x => x !== componentName)])
        }
    }

    function isLoading(componentName = "") {
        if (componentName == "") {
            return loadingArray.length > 0
        }
        else {
            return loadingArray.some(x => x == componentName);
        }
    }

    return {
        isLoading,
        loadingArray,
        setIsComponentLoading,
        adminSiteSettings,
        // This needs to be an api post that also sets the engage app context upon completion
        updateAdminSiteSettings,
        tenantSiteSettings,
        // This needs to be an api post that also sets the engage app context upon completion
        updateTenantSiteSettings,
        consentedPermissions
        //setConsentedPermissions
    }
}