import {
  tokens,
  makeStyles
} from '@fluentui/react-components'

import {
  HorizontalBarChart,
  HorizontalBarChartVariant,
  IHorizontalBarChartStyles
} from '@fluentui/react-charting';
import { useResponsive } from '../../hooks/useResponsive';
import { TopTicketCategoryReport } from '../../types/reportingTypes';
import StyledCard from '../StyledCard';
import NoData from './NoData';

const useStyles = makeStyles({
    chart: {
        height: '300px',
        width: '450px',
    },
    chartMobile: {
        width: '100%',
        marginBottom: tokens.spacingVerticalL
    },
});


function TopTicketCategories({ topTicketCategoryReport, maxRenderSize }: { topTicketCategoryReport: TopTicketCategoryReport, className?: string, maxRenderSize:number }) {

  const { isDesktopOrLaptop } = useResponsive();
  const classes = useStyles();

  const chartDataPoints = topTicketCategoryReport.categories.map((x) => {
    return {
      chartTitle: x.name,
      chartData: [
        {
          legend: x.name,
          horizontalBarChartdata: { x: x.value, y: topTicketCategoryReport.totalTickets },
          color: tokens.colorBrandForeground1
        }
      ]
    }
  }).slice(0, maxRenderSize);

    const horizontalBarChartStyles: IHorizontalBarChartStyles = {
        chartTitleRight: {
            color: tokens.colorNeutralForeground1
        },
        chartTitle: {
            color: tokens.colorNeutralForeground1
        },
        root: {},
        items: {},
        chart: {},
        chartWrapper: {},
        barWrapper: {}, 
        chartTitleLeft: { color: tokens.colorNeutralForeground1 },
        chartDataTextDenominator: {},
        benchmarkContainer: {},
        triangle: {},
        barLabel: {}
    }


  return (
    <>
      <StyledCard className={isDesktopOrLaptop ? classes.chart : classes.chartMobile} title="Top Ticket Categories">
        {chartDataPoints.length === 0 ? <NoData /> :
          <HorizontalBarChart
            styles={horizontalBarChartStyles}
            data={chartDataPoints}
            variant={HorizontalBarChartVariant.PartToWhole} />
        }
      </StyledCard>
    </>
  );
}

export default TopTicketCategories;