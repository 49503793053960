import { useEffect, useState, useContext } from "react";
import { AccountsDataContext } from "../app/contexts/AccountsDataContext";
import { EngageAppContext } from "../app/contexts/EngageAppContext";
import { LoggingContext } from "../app/contexts/LoggingContext";
import { useRest } from "./useRest";

export const useTicketsData = () => {
    const logFilePrefix = 'useTicketsData';
    const { accounts, accountError } = useContext(AccountsDataContext);
    const { setIsComponentLoading } = useContext(EngageAppContext);
    const { trackTraceVerbose } = useContext(LoggingContext);

    const [ticketsLoading, setTicketsLoading] = useState(false);
    const [tickets, setTickets] = useState<any>([]);
    const [ticketError, setTicketError] = useState<any | undefined>(undefined);
    const { POSTForm, GET, DELETE } = useRest();

    useEffect(() => {
        (async () => {
            const logName = `${logFilePrefix}-useEffect`;
            trackTraceVerbose(`${logName}`);

            if (!accounts) {
                trackTraceVerbose(`${logName}-guardClause: deps[accounts]`);
                return;
            }

            setIsComponentLoading(logFilePrefix, true);
            setTicketsLoading(true);
            if (accountError) {
                trackTraceVerbose(`${logName}-accountError`);
                setTicketsLoading(false);
                setTicketError(accountError);
                setTickets([]);
                return;
            }
            const selectedAccount = accounts.filter((x: any) => x.selected)[0];

            if (!selectedAccount?.tenantId)
                return;

            let response;
            try {
                response = await GET(`${process.env.REACT_APP_FRONTDOOR_DATA_SERVICE_BASE_URL}/Tickets/${selectedAccount.tenantId}?days=30`)

                if (!response.ok) {
                    setTicketError('Error retrieving tickets. Please try again later.');
                    setTicketsLoading(false);
                    setTickets([]);
                    return;
                }

                const data = await response.json();
                setTickets(await data);
                setTicketsLoading(false);
            }
            catch (error) {
                setTicketError(error);
            }
            finally {
                setIsComponentLoading(logFilePrefix, false);
            }

        })();

    }, [accounts]);


    // TODO ticket post should contain a non nullable tenantId
    // Create a type for ticket
    async function createTicket(ticket: FormData): Promise<any> {
        var createResponse = await POSTForm(`${process.env.REACT_APP_FRONTDOOR_DATA_SERVICE_BASE_URL}/Tickets/`, ticket);
        if (createResponse) {
            return createResponse.json();
        }
    }

    async function getTicketDetails(id: string): Promise<any> {
        const selectedAccount = accounts.filter((x: any) => x.selected)[0];

        if (!selectedAccount?.tenantId)
            return;

        setTicketsLoading(true);

        var ticketDetailsResponse = await GET(`${process.env.REACT_APP_FRONTDOOR_DATA_SERVICE_BASE_URL}/Tickets/${id}/details`);

        if (!ticketDetailsResponse.ok) {
            //setTicketError('Error retrieving ticket details. Please try again later.');
            setTicketsLoading(false);
            return;
        }

        var ticketDetails = await ticketDetailsResponse.json();

        setTicketsLoading(false);

        return ticketDetails;
    }

    async function createComment(ticketId: any, comment: FormData): Promise<any> {
        const selectedAccount = accounts.filter((x: any) => x.selected)[0];

        if (!selectedAccount?.tenantId)
            return;

        //var commentPayload = JSON.stringify(comment);
        const response = await POSTForm(`${process.env.REACT_APP_FRONTDOOR_DATA_SERVICE_BASE_URL}/Tickets/${selectedAccount.tenantId}/${ticketId}/comment`, comment);

        if (response.status === 403) {
            throw new Error('You do not have permission to add a comment to this ticket.');
        }

        if (!response.ok) {
            throw new Error('Unable to save comment. Please try again later.');
        }

        return response;
    }

    async function uploadAttachments(ticketId: string, attachmentFormData: FormData): Promise<any> {
    
        const selectedAccount = accounts.filter((x: any) => x.selected)[0];
        if (!selectedAccount?.tenantId)
            return;

        const response = await POSTForm(`${process.env.REACT_APP_FRONTDOOR_DATA_SERVICE_BASE_URL}/Tickets/${selectedAccount.tenantId}/${ticketId}/attachments`, attachmentFormData);

        if (!response.ok) {
            throw new Error('Error uploading attachments. Please try again later.');
        }

        return response;
    }

    async function downloadAttachment(ticketId: string, attachmentId: string): Promise<any> {

        const selectedAccount = accounts.filter((x: any) => x.selected)[0];
        if (!selectedAccount?.tenantId)
            return;

        const response = await GET(`${process.env.REACT_APP_FRONTDOOR_DATA_SERVICE_BASE_URL}/Tickets/${selectedAccount.tenantId}/${ticketId}/attachments/${attachmentId}`);

        if (!response.ok) {
            throw new Error('Error downloading attachment. Please try again later.');
        }

        return response;
    }

    async function deleteAttachment(ticketId: string, attachmentId: string): Promise<any> {
        
        const selectedAccount = accounts.filter((x: any) => x.selected)[0];
        if (!selectedAccount?.tenantId)
            return;

        const response = await DELETE(`${process.env.REACT_APP_FRONTDOOR_DATA_SERVICE_BASE_URL}/Tickets/${selectedAccount.tenantId}/${ticketId}/attachments/${attachmentId}`);

        if (!response.ok) {
            throw new Error('Error deleting attachment. Please try again later.');
        }
    }

    // TODO: Move to a new hook.  This hook will only be for the ticket module...
    async function getSubscriptionData(): Promise<any> {
        console.log('getSubscriptionData',accounts);
        const selectedAccount = accounts.filter((x: any) => x.selected)[0];
        console.log('getSubscriptionData-SelectedAccount', selectedAccount)
        if (!selectedAccount?.tenantId)
            return;

        console.log('getSubscriptionData-SelectedAccount', selectedAccount)
        const response = await GET(`${process.env.REACT_APP_FRONTDOOR_DATA_SERVICE_BASE_URL}/subscriptions/${selectedAccount.tenantId}`);

        if (!response.ok) {
            throw new Error('Error retrieving subscriptions. Please try again later.');
        }

        return await response.json();
    }

    return {
        data: tickets,
        ticketsLoading,
        createTicket,
        createComment,
        uploadAttachments,
        downloadAttachment,
        deleteAttachment,
        getTicketDetails,
        setTickets,
        ticketError,
        getSubscriptionData
    }
};

export default useTicketsData;
