import { useState, useContext, useEffect } from "react";
import { TabList, Tab, Divider, makeStyles, tokens } from "@fluentui/react-components"
import type {
    SelectTabData,
    SelectTabEvent,
    TabValue,
} from "@fluentui/react-components";

import StyledCard from "../../components/StyledCard"
import PermissionsSettings from "./Permissions";
import LicensingSettings from "./Licensing";
import AdminSettings from "./Admin";
import { EngageAppContext } from "../contexts/EngageAppContext";
import { ContactsDataContext } from "../contexts/ContactsDataContext";
import { ENGAGE_USER_ROLES, DEFAULT_DESKTOP_PAGE_SIZE } from "../constants";
import { useResponsive } from "../../hooks/useResponsive";

const useStyles = makeStyles({
    page: {
        minWidth: `${DEFAULT_DESKTOP_PAGE_SIZE}px`,
    },
    mobilePage: {
        width: '100%',
    },
    content: {
        marginTop: tokens.spacingVerticalL,
        minHeight: '500px'
    }
})

export default function Settings() {

    const adminName = 'admin';
    const licensingName = 'licensing';
    const permissionsName = 'permissions';


    const { adminSiteSettings } = useContext(EngageAppContext);
    const { contact } = useContext(ContactsDataContext)
    const { isDesktopOrLaptop } = useResponsive();

    const [selectedTab, setSelectedTab] = useState<TabValue>("permissions")
    const classes = useStyles();

    const getTitle = () => {

        return (
            <TabList
                selectedValue={selectedTab}
                onTabSelect={handleTabSelect}>
                <Tab value="permissions">Permissions</Tab>
                {adminSiteSettings.isLicensingModuleEnabled && canAccess(licensingName) && <Tab value={licensingName}>Licensing</Tab>}
                {canAccess(adminName) && <Tab value={adminName}>Admin</Tab>}
            </TabList>
        )
    }

    const handleTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
        setSelectedTab(data.value)
    }

    const canAccess = (navName: string) => {
        if (!contact) {
            return false;
        }

        const isAdministrator = contact.userRoles.some(x => x == ENGAGE_USER_ROLES.ADMINISTRATOR);
        const hasGlobalRead = contact.userRoles.some(x => x == ENGAGE_USER_ROLES.GLOBAL_READ);

        if (navName == adminName) {

            if (contact === undefined)
                return false;

            return isAdministrator || hasGlobalRead;
        }

        if (navName == licensingName) {

            if (isAdministrator || hasGlobalRead)
                return true;

            // Only Pax8LicensingAdmin can modify recipients/autotrim
            if (contact.userRoles.some(x => x == ENGAGE_USER_ROLES.PAX8_LICENSING_ADMIN))
                return true;

            return false;
        }
    }

    return (
        <div className={isDesktopOrLaptop ? classes.page : classes.mobilePage}>
            <StyledCard
                title={"Configuration"}>

                <div>
                    {getTitle()}
                    <Divider
                        alignContent="start"
                        appearance="strong" />
                    <div className={classes.content}>
                        {selectedTab === "permissions" && <PermissionsSettings />}
                        {selectedTab === "licensing" && <LicensingSettings />}
                        {selectedTab === "admin" && <AdminSettings />}
                    </div>
                </div>
            </StyledCard>
        </div>
    )
}