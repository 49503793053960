import { useEffect, useState, useContext } from 'react';
import { Table, TableHeader, TableRow, TableCell, TableHeaderCell, TableBody, makeStyles, shorthands, Button, Body1, tokens, Body1Stronger } from '@fluentui/react-components';
import { AccountsDataContext } from '../contexts/AccountsDataContext';
import { LicensingDataContext } from '../contexts/LicensingDataContext';
import { EngageAppContext } from '../contexts/EngageAppContext';
import Currency from '../../components/Currency';
import { fonts } from '../../styles'

import { date } from '../../utils'
import SparkLine from '../../components/Sparkline';
import { Open24Regular } from '@fluentui/react-icons';
import { useNavigate } from 'react-router-dom';
import EngageTable from '../../components/EngageTable';
import NoSubscriptionData from './NoSubscriptionData';
import { useResponsive } from '../../hooks/useResponsive';

const useStyles = makeStyles({
    tableBody: {
        ...fonts.body
    },
    chartableColumn: {
        width: '120px',
    },
    productColumn: {
        width: '30%',
        textOverflow: 'ellipsis',
    },
    temp: {
        ...shorthands.border('1px', 'solid', 'black')
    },
    productsProductNameColumn: {
        minWidth: '300px',
        whiteSpace: 'nowrap',
        overflowX: 'hidden',
        textOverflow: 'ellipsis',
        paddingRight: '10px'

    },
    centerJustified: {
        textAlign: 'center'
    },

    currencyColumnHeaders: {
        width: '150px',

        '& div': {

            display: 'flex',
            justifyContent: 'end'
        }
    },
    currencyColumns: {
        width: '150px',
        textAlign: 'right'
    },
    productsCheckboxColumn: {
        width: '25px'

    },
    billingCard: {
        //height: '300px'

    },
    billingWidgetLayout: {
        display: 'flex',
        flexDirection: 'row',
        //justifyContent: 'space-around',
    },
    costByBillingType: {
        display: 'inline'
    },
    billingLine: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    standardColumn: {
        width: '75px'
    },


});

const createProvisionedSparkline = (productHistory: any) => {
    if (productHistory.length == 0) {
        return <></>
    }

    const data = productHistory.map((record: any, index: number) => {
        return record.subscribedSkuQuantity;
    })

    return createSparklineChart(data)
}

const createUnassignedSparkline = (productHistory: any[]) => {
    if (productHistory.length == 0) {
        return <></>
    }

    const data = productHistory.map((record: any, index: number) => {
        return record.subscribedSkuQuantity - record.subscribedSkuAssignedQuantity
    })

    return createSparklineChart(data)
}

const createSparklineChart = (data: any[]) => {

    return (<>
        <SparkLine data={data} showLegend={true} />
    </>)
}

export default function CostDashboard() {

    const logFilePrefix = 'Subscriptions';

    const classes = useStyles();

    const { isDesktopOrLaptop } = useResponsive();

    const { accounts } = useContext(AccountsDataContext);
    const { subscriptions } = useContext(LicensingDataContext);
    const { isLoading } = useContext(EngageAppContext);
    const [engageProducts, setEngageProducts] = useState<any>([]);
    const [billingWidgetData, setBillingWidgetData] = useState<any>();
    const [selectedProducts, setSelectedProducts] = useState<any[]>([]);
    const navigate = useNavigate();

    const handleProductChecked = (_ev: any, data: any) => {
        const productMicrosoftSku = _ev.target.value;
        const isChecked = data.checked;
        if (isChecked) {
            setSelectedProducts([...selectedProducts, productMicrosoftSku])
        } else {
            setSelectedProducts(selectedProducts.filter(x => x != productMicrosoftSku))
        }

        // add if it does not exist, remove if it does
    }

    const handleUpdateProductClick = () => {
        console.log(`${logFilePrefix}-UpdateProductClick`, selectedProducts)

        navigate('/subscriptions/edit', {
            state: {
                products: engageProducts.filter((item: any) => selectedProducts.includes(item.productId))
            }
        })
    }

    const handleAddProductClick = () => {
        console.log(`${logFilePrefix}-RequestProductClick`, selectedProducts)
        navigate('/subscriptions/request');
    }

    const getFooter = () => {
        return (
            <>
                <Button
                    iconPosition='after'
                    icon={<Open24Regular />}
                    appearance='primary'
                    onClick={handleUpdateProductClick}>Update Product(s)</Button>
            </>
        )
    }

    useEffect(() => {
        (
            async () => {
                console.log('useEffect-subscriptionData')

                if (subscriptions.length <= 0) {
                    return;
                }

                setEngageProducts(subscriptions);
                // var data = await getSubscriptionData()
                //setEngageProducts(data);

            }
        )();
    }, [subscriptions]);

    return (

        <>
            {!isLoading('useLicensingData') &&
                <EngageTable>
                    <TableHeader>
                        <TableRow>
                            <TableHeaderCell className={classes.productsProductNameColumn}>Subscription Name</TableHeaderCell>
                            {isDesktopOrLaptop &&
                                <TableHeaderCell className={classes.chartableColumn}>Provisioned</TableHeaderCell>
                            }
                            <TableHeaderCell className={classes.chartableColumn}>Unassigned</TableHeaderCell>
                            <TableHeaderCell className={classes.standardColumn}>Term End</TableHeaderCell>

                            <TableHeaderCell className={classes.currencyColumnHeaders}><div>Billed MSRP Monthly</div></TableHeaderCell>
                            <TableHeaderCell className={classes.currencyColumnHeaders}><div>Billed MSRP Annually</div></TableHeaderCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody className={classes.tableBody}>
                        {engageProducts?.map((engageProduct: any) => {
                            const numSubs = engageProduct.subscriptions.length;
                            return (
                                engageProduct.subscriptions.map((subscription: any, index: number) => {
                                    return (
                                        <TableRow>
                                            {index === 0 &&

                                                <>
                                                    <TableCell rowSpan={numSubs} className={classes.productsProductNameColumn}>{engageProduct.productName}</TableCell>
                                                    {isDesktopOrLaptop &&
                                                        <TableCell className={classes.chartableColumn} rowSpan={numSubs}>{createProvisionedSparkline(engageProduct.history)}</TableCell>
                                                    }
                                                    <TableCell className={classes.chartableColumn} rowSpan={numSubs}>{createUnassignedSparkline(engageProduct.history)}</TableCell>
                                                </>
                                            }
                                            <TableCell className={classes.standardColumn}>{date(subscription?.commitmentTerm?.endDate)}</TableCell>
                                            <TableCell className={classes.currencyColumns}>
                                                {subscription.billingTerm === "Monthly" ? <Currency dollarAmount={subscription.quantity * subscription.price} /> : <Currency dollarAmount={0} />}
                                            </TableCell>
                                            <TableCell className={classes.currencyColumns}>
                                                {subscription.billingTerm === "Annual" ? <Currency dollarAmount={subscription.quantity * subscription.price} /> : <Currency dollarAmount={0} />}
                                            </TableCell>
                                        </TableRow>)
                                })
                            )
                        })}
                        <TableRow>
                            <TableCell><Body1Stronger>Total</Body1Stronger></TableCell>
                            { isDesktopOrLaptop && <TableCell></TableCell>}
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell className={classes.currencyColumns}>
                                <Body1Stronger>
                                    <Currency dollarAmount={engageProducts.reduce((total: any, sub: any) => total + sub.totalBilledMonthly, 0)} />
                                </Body1Stronger>
                            </TableCell>
                            <TableCell className={classes.currencyColumns}>
                                <Body1Stronger>
                                    <Currency dollarAmount={engageProducts.reduce((total: any, sub: any) => total + sub.totalBilledAnnually, 0)} />
                                </Body1Stronger>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </EngageTable >
            }
            {isLoading('useLicensingData') && <Body1>Loading licensing data</Body1>}
        </>
    );
}