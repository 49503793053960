import { useEffect, useContext } from 'react';

import {
  makeStyles,
  tokens,
} from '@fluentui/react-components';

import OpenTickets from '../../components/tickets/OpenTickets'
import ClosedTickets from '../../components/tickets/ClosedTickets'
import OnHoldTickets from '../../components/tickets/OnHoldTickets'
import ErrorPage from '../../error-page';

import { LoggingContext } from '../contexts/LoggingContext';
import { AccountsDataContext } from '../contexts/AccountsDataContext';
import { useResponsive } from '../../hooks/useResponsive';

const useStyles = makeStyles({
  page: {
    minWidth: '1200px',
  },
  mobilePage: {
    width: '100%',
  },
  supportRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '170px'
  },
  row: {
    marginTop: tokens.spacingVerticalXL
  },
  none: {

  }
});

const Assistance = () => {
  const classes = useStyles();
  const { trackPageView, getSessionId } = useContext(LoggingContext);
  const { isDesktopOrLaptop } = useResponsive();
  const { accountError } = useContext(AccountsDataContext);

  useEffect(() => {
    // after everything has loaded track pageView
    trackPageView('assistance');
  }, []);

  return (
    <div className={isDesktopOrLaptop ? classes.page : classes.mobilePage}>
      {accountError && <ErrorPage sessionId={getSessionId()} />}

      {!accountError &&
        <div>
          <OpenTickets className={classes.none} />
          <div className={classes.row}>
            <OnHoldTickets className={classes.row} />
          </div>
          <div className={classes.row}>
            <ClosedTickets className={classes.row} />
          </div>
        </div>
      }
    </div>
  );
}

export default Assistance;